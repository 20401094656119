import React from 'react';
import { graphql} from 'gatsby';
import Features from '../../templates/multilanguage/Features';

export default (props) => {
  return <Features {...props} />;
};

export const query = graphql`
  query FeaturesListRo {
    features: allMdx(filter: {frontmatter: {type: {in: ["feature-index", "feature"]} language: {eq: "de"}}}) {
      posts: nodes {
        id
        body
        fields {
            slug
            canonical
            lang
            relatedPages {
              en {
                url
                country
              }
              it {
                url
                country
              }
              ro {
                url
                country
              }
              es {
                url
                country
              }
              fr {
                url
                country
              }
              de {
                url
                country
              }
              pl {
                url
                country
              }
              cz {
                url
                country
              }
            }
        }
        exports {
          categories {
            name
            index
          }

          sections {
            orientation
            title
            icon {
              publicURL
            }
            alt
            descriptions
          }
        }
        frontmatter {
          type
          title
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
          metaCover {
            publicURL
          }
          metaKeywords
          twitterCard
          ldJson
          cardTitle
          carouselTitle
          excerpt
          carouselDescription
          chipTitles
          cover {
            publicURL
          }
          coverAlt
        }
      }
    }
  }
`
